.lightgraybg{background:rgba(149, 152, 157, 0.1);}
.contactbgimage{position: absolute;top: 0;width: 100%;height: 100%;object-fit: cover;z-index: 0;}
.customzindex{position: relative;z-index: 10;}
/* .contactboxes .section-title{-webkit-background-clip: text;-webkit-text-fill-color: transparent;background-position: center;background-size: cover;} */
.contactboxes a{text-decoration: none;color: var(--blackcolor);}
.contacticon {width: 25px; flex: 0 0 auto;margin-right: 10px;position: relative;top: -10px;}
.contactaction a{text-decoration: none;color: var(--blackcolor);}
.contactaction .contacttitle{font-size: 18px;color: var(--bluecolor);line-height: 18px;margin-bottom: 15px;font-weight: 700;}
.contactaction p{margin-bottom: 0px;}
.contacticoncontent{font-size: 19px;line-height: normal;}
.contactboxes{ font-size: 20px;line-height: 28px;}