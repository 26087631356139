.banner-slider .owl-nav, .logo-slider .owl-nav{position: static;width: 100%;}
.itemcaption-content-main::before{
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
}
.itemcaption-content{
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  z-index: 100;
  padding: 0px 55px;
}
.banner-img{
  height: 380px;
  object-fit: cover;
  width: 100%;
}
.bg-gray{
  background: var(--graycolor);
}
.bg-light-gray{background: #f5f5f5;}
.rectangular-box{
  border-radius: 60px 0px 0px 0px;
  background: var(--bluecolor);
  padding: 30px 20px;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 1.5rem;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.5s;
  transform: scale(1.0);
}
.rectangular-box img{transform: rotate(0deg);transition: all 0.8s;}
.rectangular-box:hover, .rectangular-box.active, .active .rectangular-box{
  background: url('../images/rectangular-hover-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(1.05);
  
}
.rectangular-box:hover img{transform: rotate(360deg);}
.rectangular-box img{height: 80px;width: 90px; object-fit: contain;}
.service-title{
  font-size: 13px;
  line-height: 20px;
  font-weight: 700;
}
.banner-slider .owl-prev{position: absolute;left: 15px; top: 50%; transform: translateY(-50%);margin: 0px !important;}
.banner-slider .owl-prev:hover, .banner-slider .owl-next:hover{background: none !important;}
.banner-slider .owl-next{position: absolute;right: 15px;top: 50%; transform: translateY(-50%) rotate(180deg);margin: 0px !important;}
.logo-slider .owl-prev{position: absolute;left: 15px;top: 50%; transform: translateY(-50%);margin: 0px !important;}
.logo-slider .owl-prev:hover, .logo-slider .owl-next:hover{background: none !important;}
.logo-slider .owl-next{position: absolute;right: 15px;top: 50%; transform: translateY(-50%) rotate(180deg);margin: 0px !important;}
.doorlist{list-style: none;padding-left: 0;display: flex;justify-content: space-between;align-items: center;flex-wrap: wrap;margin-bottom: 0px;}
.doorlist li{ padding: 0px 20px;position: relative;}
.doorlist li:first-child{padding-left: 0px;text-align: left;}
.doorlist li:last-child{padding-right: 0px;text-align: right;}
.doorlist li{width: 33.33%;text-align: center;}
.doorlist li:nth-child(4){width: 100%;text-align: center;margin: 40px 0px;}
.doorlist li:nth-child(1)::after, .doorlist li:nth-child(2)::after, .doorlist li:nth-child(3)::after{ content: '';background: url('../images/door-prev.svg');width: 12px;height: 20px;background-size: cover;background-repeat: no-repeat;position: absolute;right: 0;top: 50%;transform: translateY(-50%);}
.doorlist li:nth-child(5)::after, .doorlist li:nth-child(6)::after, .doorlist li:nth-child(7)::after{ content: '';background: url('../images/door-next.svg');width: 12px;height: 20px;background-size: cover;background-repeat: no-repeat;position: absolute;left: 0;top: 50%;transform: translateY(-50%);}
.font-weight-bold{font-weight: 700;}
.kvtfont-industry{font-size:12px;line-height: 18px;font-family: var(--kvcfont);}
.case-img{border-radius: 30px 0px 0px 0px;box-shadow: 22px 22px 60px #00000029;height: 300px;object-fit: cover;}
.hovercontent{font-size: 20px;line-height: 30px;background: rgba(0,0,0, 0.5);position: absolute;top: 0;width: 100%;height: 100%;color: #ffffff;padding: 20px;
  border-radius: 30px 0px 0px 0px;font-weight: 400;display: flex;flex-direction: column;justify-content: space-between;opacity: 0;visibility: hidden; transition: all 0.5s;}
.linkicon{transform: rotate(180deg);}
.casediv-main:hover .hovercontent{opacity: 1;visibility: visible;}
.formfont label, .formfont1 label{margin-bottom: 0;font-size: 18px;text-transform: capitalize;}
.formfont .col-md-6:nth-child(5){width: 100%;}
.formfont #Message{height: 80px;}
.formfont .form-control, .formfont1 .form-control{padding: 0.7rem 0.75rem;}
.customsliderpadding{
  padding-left: 30px;
  padding-right: 30px;
}
.logo-slider .owl-prev{left: -40px;}
.logo-slider .owl-next{right: -40px;}
.homesection2slider .slick-list img{height:80px;object-fit: contain;}
.banner-slider .slick-prev, .banner-slider .slick-next{z-index: 100;width: 40px; height: 40px;}
.banner-slider .slick-prev{left: 10px;}
.banner-slider .slick-next{right: 10px;transform: translate(0, -50%) rotate(180deg);}
.logo-slider .slick-next{transform: translate(0, -50%) rotate(180deg);}
.logo-slider .slick-slide img{    margin: 0 auto;}
.logo-slider .slick-prev, .logo-slider .slick-next{z-index: 100;width: 25px; height: 26px;}
.logo-slider .slick-next{right: -30px;}
.logo-slider .slick-prev{left: -30px;}
.iti{display: block;font-size: 15px;}
.hovercontent-homeservicebox{position: absolute;font-size: 16px;color: #ffffff;line-height: 22px;background: rgba(0, 0, 0, 0.70);padding: 7px;top: 0;width: 100%;height: 100%; left: 0;opacity: 0;visibility: hidden;z-index: 10;padding: 30px 20px;}
.hovercontent-homeservicebox p{margin-bottom: 0;}
.rectangular-box:hover .hovercontent-homeservicebox{opacity: 1;visibility: visible;}
.clicktoshowcontent{padding: 1rem 0rem;background: #008aaa;color: #ffffff;}
.clicktoshowcontent p{margin-bottom: 0;}
.bannervideo{width: 100%;height:450px;object-fit: cover;display: block;}
.statsicon{height: 100px;}
@media (min-width:576px){
  .rectangular-box{width: 100%;}
}
@media (min-width:768px){
  .itemcaption-content{padding: 0px 0px;}
  .banner-slider .slick-prev{left: 20px;}
.banner-slider .slick-next{right: 20px;}
.customsliderpadding{padding-left: 50px;padding-right: 50px;}
.case-img{height:350px;}
}
@media (min-width:992px){
  .bannervideo{width: 100%;height: 600px;object-fit: cover;object-position: 0px -80px;}
  .kvtfont-industry{font-size:18px;line-height: 28px;}
  .doorlist li{ padding: 0px 40px;}
  .doorlist li{
    width: 13%;
  }
  .doorlist li:nth-child(4){
    width: 22%;
    margin: 0;
  } 
  .row-cols-lg-7>*{flex: 0 0 auto;width: 14.285%;}
  .doorlist li:first-child{text-align: center;}
}

@media (min-width:1400px){
 
  
  .itemcaption-content{max-width: 865px;}
}
@media (min-width:1600px){
  .service-title{font-size: 16px;}
}