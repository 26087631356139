.about-banner img{height: 300px;object-fit: cover;}
.about-banner{position: relative;z-index: 0;}
.about-banner::before{position: absolute;content: '';width: 100%;height: 100%;background: rgb(255,255,255);background: linear-gradient(90deg, rgb(221 221 221) 0%, rgb(221 221 221) 40%, rgba(255,255,255,0) 100%);z-index: 100; top: 0;left: 0;}
.about-banner-content{position: absolute;top: 50%;left: 0;right: 0; transform: translateY(-50%);z-index: 100;}
.transport-content {  background-color: var(--bluecolor); border-radius: 60px 0px 0px 0px; padding: 20px; }
.transport-image{overflow: hidden;border-radius: 60px 0px 0px 0px;}
.transport-image img{width: 100%;height: 300px;object-fit: cover;}
.transport-content p:last-child, .transport-inner-content p:last-child{margin-bottom: 0px;}
.vission-image img{width: 80%;}
@media (min-width:992px){
  .transport-content{font-size: 20px;line-height: 28px;}
  .transport-image img {border-radius: 60px 0px 0px 0px;height: 410px;object-fit: cover;transition: all 1s;}
  .transport-image img:hover{transform: scale(1.1);}
  .transport-content {  background-color: var(--bluecolor); border-radius: 60px 0px 0px 0px; padding: 40px; margin-left: -100px; z-index: 10;position: relative;}
  .vission-image img{width: 100%;transform: rotate(0deg);}
}

/* mission-section-start */
.mission-section {  
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.missionbg-image{position: absolute;width: 100%;height: 100%;object-fit: cover;}
.mission-section::after {
  content: '';
  background: linear-gradient(90deg, rgba(7, 106, 129, 1) 3%, rgba(0, 138, 170, 1) 7%, transparent 79%);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.mission-section .container {
  z-index: 100;
}

.vission-inner-image h3 {
  font-size: 40px;
  line-height: 56px;
  color: #3D3F42;
  font-family: var(--kvcfont);
  font-weight: bold;
  margin-left: 10px;
  margin-bottom: 0;
  text-transform: uppercase;
}

.vission-content ul li {
  font-family: var(--fontbody);
  font-size: 18px;
  line-height: 30px;
  font-weight: normal;
  color: #3D3F42;
}

.vission-content {
  background-color: #E7E8E9;
  border-radius: 25px 0px 0px 0px;
  padding: 16px;
}

/* mission-section-end */

.gradientbox{border: 5px solid #95989D;border-radius: 60px 0px 0px 0px;background: transparent linear-gradient(180deg, #95989D00 0%, #95989D99 100%) 0% 0% no-repeat padding-box;padding: 2rem;height: 100%;font-size: 18px;line-height: 25px;}
.distinguisimg{width:40px; height: 40px;flex: 0 0 auto;margin-right: 5px;}
.distinguisimg img{width: 100%; height: 100%;object-fit: contain;}
.ourcomunitycolumn .col-md-4{padding: 2rem;border-bottom:1px solid var(--white)}
.ourcomunitycolumn .col-md-4:last-child{border-bottom: none;}
.ourcomunitycolumn .section-title{letter-spacing: 0px;}
.aboutbg::before{content: '';width: 100%;height: 100%;left: 0;top: 0;background: rgb(0,0,0, 0.01);position: absolute;}
.teamsectionabout{background: #f3f3f3;}
@media (min-width:768px){
  .ourcomunitycolumn .col-md-4{border-right: 1px solid var(--white);border-bottom:1px solid var(--white);padding: 3rem;}
  .ourcomunitycolumn .col-md-4:nth-child(4), .ourcomunitycolumn .col-md-4:nth-child(5), .ourcomunitycolumn .col-md-4:nth-child(6){border-bottom: none;}
  .ourcomunitycolumn .col-md-4:nth-child(3n){border-right: none;}
  .gradientbox .section-title{font-size: 28px;line-height: 32px;}
  .gradientbox{width: 100%;}
}
@media (min-width:1200px){
  .distinguisimg{width: 62px; height: 62px;}
  .gradientbox .section-title{font-size: 30px;line-height: 36px;}
}