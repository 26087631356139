@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
:root{
  --blackcolor:#000000;
  --bluecolor: #008AAA;
  --yellowcolor:#FFB400;
  --graycolor:rgb(149 152 157 / 20%);
  --white:#ffffff;
  --fontbody:'Montserrat', sans-serif;
  /* --titlefont:'typofont', sans-serif; */
  --titlefont:'Montserrat', sans-serif;
  --kvcfont:'Montserrat', sans-serif;
  
}

@font-face {
  font-family: 'typofont';
  src: url('assets/fonts/typo-square-demo.OTF') format('opentype');
  font-weight: normal;
  font-style: normal;
}
/* @font-face {
  font-family: 'kvcfont';
  src: url('assets/fonts/kvc-brute-demo.OTF') format('opentype');
  font-weight: normal;
  font-style: normal;
} */
body{
  font-family: var(--fontbody);
  font-size: 18px;
    line-height: 32px;
}
.text-blue{color: var(--bluecolor);}
.line-height-normal{line-height: normal !important;}
.blue-bg{background: var(--bluecolor);}
.kvcfont{font-family: var(--kvcfont);}
/*== Button ==*/
.btn-primary {font-family: var(--titlefont); color: var(--white);background-color: var(--bluecolor); border-color: var(--bluecolor); border-radius:8px; text-decoration:none;padding: 7px 30px 10px 30px;font-size: 18px;}
.btn-primary:hover, .btn-primary:active, .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled):active:focus{background:var(--blackcolor); color:var(--white); border-color:var(--blackcolor);box-shadow:none;}
.btn-yellow {font-family: var(--titlefont); color: var(--blackcolor);background-color: var(--yellowcolor); border-color: var(--yellowcolor); border-radius:8px; text-decoration:none;padding: 7px 30px 10px 30px;font-size: 18px;}
.btn-yellow:hover, .btn-yellow:active, .btn-yellow:focus, .btn-yellow:not(:disabled):not(.disabled):active, .btn-yellow:not(:disabled):not(.disabled):active:focus{background:var(--bluecolor); color:var(--white); border-color:var(--bluecolor);box-shadow:none;}
.btn-yellow svg{margin-left: 10px;transform: rotate(130deg); transition: all 0.5s;}
.btn-yellow:hover svg{transform: rotate(180deg);}
.btn-yellow:hover svg path{fill: #ffffff;}
/*== Button End ==*/
/* Loader css start */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/*loader css end */

.back-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #1dafd1;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 50px;
    height: 50px;
    border-radius: 50%;
  border: 3px solid rgba(255, 255, 255, 0.5);
  z-index: 1000;
}
.back-to-top-button img{
  transform: rotate(90deg);
  position: relative;
  top: -1px;
}

.back-to-top-button:hover {
  background-color: #1dafd1;
}

/* header css start */
header{ position: sticky;top: 0;z-index: 1000;box-shadow: 0px 3px 15px #00000029;}
.header-navigation .navbar-nav .nav-link{
  font-size: 18px;
  line-height: 39px;
  font-weight: bold;
  color: var(--blackcolor);
  font-family: var(--kvcfont);
}
.header-navigation .navbar-nav .nav-link:hover, .header-navigation .navbar-nav .nav-link:focus{
  color: var(--bluecolor);
}
.header-navigation .navbar-nav .nav-link.nav-btn{
  font-size: 18px;
  line-height: 39px;
  background: var(--yellowcolor);
  border-radius: 10px;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
}

.header-navigation .navbar-nav .nav-link.nav-btn:hover, .header-navigation .navbar-nav .nav-link.nav-btn:focus{
  background: var(--bluecolor);
  color: var(--white);
}
.header-navigation .navbar-nav .nav-link.nav-btn svg{margin-left: 10px;transform: rotate(130deg); transition: all 0.5s;}
.header-navigation .navbar-nav .nav-link.nav-btn:hover svg{transform: rotate(180deg);}
.header-navigation .navbar-nav .nav-link.nav-btn:hover svg path{fill: #ffffff;}
button.navbar-toggler:focus:not(:focus-visible), button.navbar-toggler:focus{outline: none;box-shadow: none;}
.navbar-toggler{padding: 0px;border: none;}

.trigger {
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  border: 2px solid #fff;
  border-radius: 10px;
  transform: translateX(-50%) translateY(-50%);
}

.bars {
width: 60px;
cursor: pointer;
}
.bars .line {
fill: none;
stroke: var(--bluecolor);
stroke-width: 4;
stroke-linecap: square;
transition: stroke-dasharray 400ms,  stroke-dashoffset 400ms;
}
.bars .line.top {
stroke-dasharray: 40 172;
}
.bars .line.middle {
stroke-dasharray: 40 111;
}
.bars .line.bottom {
stroke-dasharray: 40 172;
}
.bars.active .top {
stroke-dashoffset: -132px;
}
.bars.active .middle {
stroke-dashoffset: -71px;
}
.bars.active .bottom {
stroke-dashoffset: -132px;
}

/* header css end */
/*Footer css start */

.footer-bg{
  background: var(--bluecolor);
  background-position: bottom center;
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  background-repeat: no-repeat;
}
.footer-list{list-style: none;padding-left: 0;}
.footer-conact-deatils .icondiv{padding-right: 10px;}
.copy-text{font-size: 16px;line-height: 24px;}
.social-list{list-style: none;padding-left: 0px;}
.social-list li{display: inline-block;}
.social-list li + li{padding-left: 10px;}
.social-list li a{position: relative;top: 0px; transition: all 0.5s;display: block;}
.social-list li a:hover{position: relative;top: -2px;}
.social-list li a:hover img{filter: brightness(1) invert(1);}
.contentdiv strong{font-weight: bold;}
.contentdiv a{text-decoration: none;color: var(--white);}
.termlinks a:not(:last-child)::after{
  content: '|';
  padding-left: 5px;
}
.termlinks a:not(:last-child){
  padding-right: 5px;
}
.footer-title{font-weight: 700;margin-bottom: 1rem;}
@media (min-width:992px){
  .footer-title{margin-bottom: 3rem;margin-top: 3rem;}
}
.footer-logos-main{margin: 0 -5px;}
.footer-accreditions-logo{padding: 0px 5px; width: 100%; width: 50%;margin-bottom: 10px;}
.footer-accreditions-logo img{padding: 5px;width: 100%;background: #ffffff;border-radius: 5px;height: 80px;object-fit: contain;overflow: hidden;}
/*Footer css end */
.lifeimg{width: 100%;height: 250px;object-fit: cover;border-radius: 10px;object-position: top center;cursor: pointer;}
.page-title{
  font-size: 28px;
  line-height: 40px;
  font-family: var(--kvcfont);
  font-weight: 900;
}
.section-title{
  font-size: 28px;
  line-height: 38px;
  font-family: var(--kvcfont);
  font-weight: 800;
  text-transform: capitalize !important;
  color: #3D3F42;
}
.typofont{font-family: var(--titlefont);}
.kvtfont{font-family: var(--kvcfont);}
.section-spacing{padding: 40px 0px;}
.fw-20{font-size: 16px;line-height: 26px;}
.breadcrumb{font-size: 16px;}
.breadcrumb a{text-decoration: none;color: var(--blackcolor);}
.breadcrumb-item+.breadcrumb-item::before{content: var(--bs-breadcrumb-divider, "|");}
.breadcrumb .active a{color: var(--bluecolor);}
.gallerymodal .btn-close{position: absolute;right: 0;top: -40px;opacity: 1;background: transparent url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e) center/1em auto no-repeat;}
.gallerymodal .modal-content{border: none;}
.gallerymodal .modal-content .modal-body{padding: 0;}

@media (min-width:576px){
  .page-title{
    font-size: 36px;
  }
}
@media (min-width:992px){
  .section-spacing{padding: 80px 0px;}
  .header-navigation .navbar-nav .nav-item{
    padding: 0px 7px;
  }
  .header-navigation .navbar-nav .nav-item:last-child{
    padding-right: 0px;
  }
  .page-title{
    font-size: 60px;
    line-height: 84px;
  } 
  .section-title{
    font-size: 40px;
    line-height: 56px;
  }
  .fw-20{font-size: 20px;line-height: 30px;}
  .header-navigation .navbar-nav .nav-link{    
    transition: all 0.5s;
    position: relative;
    left: 0;
  }
  .header-navigation .navbar-nav .nav-link:hover, .header-navigation .navbar-nav .nav-link:focus{
    position: relative;
    left: 10px;
  }
}

@media (min-width:1200px){
  .header-navigation .navbar-nav .nav-item{
    padding: 0px 15px;
  }
  .header-navigation .navbar-nav .nav-link{
    font-size: 22px;
  }
  body{
    font-size: 24px;
    line-height: 35px;
  }
  
}
