.customtabsservices li{margin-bottom: 15px;}
.tabbingsectionbg{background: rgba(149, 152, 157, 0.1);}
.customtabsservices{border-bottom: none;}
.tabbingsectionbg .accordion-item{border: none;background: transparent;margin-bottom: 10px;}
.tabbingsectionbg .accordion-item .accordion-button{font-family: var(--kvcfont);}
.tabbingsectionbg .accordion-item .accordion-button br, .tabbingsectionbg .accordion-item .accordion-header br{display: none;}
.tabbingsectionbg .accordion-item .accordion-button{background: var(--bluecolor);color: #ffffff;}
.tabbingsectionbg .accordion-item .accordion-button:not(.collapsed){background: var(--bluecolor);color: var(--white);outline: none;box-shadow: none;}
.customtabsservices li .nav-link{display: flex;width: 100%;background: var(--bluecolor);align-items: center; color: var(--white);border-radius: 0;font-size: 16px; line-height: 20px;font-family: var(--kvcfont);padding: 15px 40px;position: relative;border: none;text-align: left;}
.customtabsservices.righttabs li .nav-link{padding: 15px 40px 15px 15px;text-align: right;}
.customtabsservices li .nav-link.active{background: #076A81;color: var(--white);}
.customtabsservices li .nav-link img{height: 55px;margin-right: 10px;object-fit: contain;width: 40px;}
.customtabsservices li .nav-link::after{content: ""; position: absolute; right: -40px; bottom: 0; width: 40px; height: 100%; clip-path: polygon(0 0, 50% 50%, 0 100%);  background: var(--bluecolor);}

.customtabsservices li .nav-link.active::after{content: ""; position: absolute; right: -40px; bottom: 0; width: 40px; height: 100%; clip-path: polygon(0 0, 50% 50%, 0 100%);  background: #076A81;}
.customtabsservices li .nav-link::before{content: ""; position: absolute; left: 0; bottom: 0; width: 40px; height: 100%; clip-path: polygon(0 0, 50% 50%, 0 100%);  background: rgb(244 244 245);}
.tablist li{margin-bottom: 15px;}
.servicebg{position: absolute;top: 0;width: 100%;height: 100%;object-fit: cover;z-index: 0;display: none;}
.customz-index{position: relative;z-index: 10;}
.accordion-button::after, .accordion-button:not(.collapsed)::after{background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");}
.bluebg{background: var(--bluecolor);}
.stratargyicon{width: 120px;margin: 0 auto;}
.accordion-button img{width: 50px;margin-right: 10px;}
.bluebg{background: var(--bluecolor);}
.servicetitle{display: flex; flex-wrap: wrap;align-items: center;margin-bottom: 20px;font-size: 24px;}
.servicetitle img{width: 80px; height: 60px;object-fit: contain; margin-right: 5px;}
.servicetoshow>div{display: none;}
.servicetoshow>div.active{display: block;}
/* .customscrollcss{overflow-x: scroll;flex-wrap: nowrap;}
.customscrollcss::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 0px;
}
.customscrollcss::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}
.customscrollcss::-webkit-scrollbar-thumb
{
	border-radius: 0px;
	background: linear-gradient(90deg, rgba(4,104,128,1) 0%, rgba(0,138,170,1) 100%);
} */
.wp-block-heading{font-size: 32px;margin-bottom: 1rem;color: var(--bluecolor);}
.wp-block-image img{width: 100%;}
.servicetoshow ul li{margin-bottom: 10px;}
.previvon{position: absolute;top: 50%;transform: translateY(-50%);left: 10px;padding: 5px;}
.nexticon{position: absolute;top: 50%;transform: translateY(-50%) rotate(180deg);right: 10px;padding: 5px;}
@media (min-width:992px){
  .previvon{left: -60px;background: transparent;border: none;}
.nexticon{right: -60px;background: transparent;border: none;}
  .servicetitle{font-size: 30px;}
  .righttabs.customtabsservices li .nav-link{flex-direction: row-reverse;}
  .righttabs.customtabsservices li .nav-link img{margin-right: 0px;margin-left: 10px;}
  .righttabs.customtabsservices li .nav-link:before{
    content: "";
    position: absolute;
    right: 0;
    left: inherit;
    bottom: 0;
    width: 40px;
    height: 100%;
    clip-path: polygon(0 0, 62% 50%, 0 100%);
    background: rgb(244 244 245);
    transform: translate(0.7%) rotate(180deg);
  }
  .servicebg{display: block;}
  .wp-block-image img{width: 50%;margin: 0px 15px 15px 0px;float: left;height: 450px;object-fit: cover;}
  .righttabs.customtabsservices li .nav-link::after {
    content: "";
    position: absolute;
    position: absolute;
    left: -40px;
    bottom: 0;
    width: 40px;
    height: 100%;
    clip-path: polygon(0 0, 51% 51%, 0 100%);
    background: var(--bluecolor);
    transform: translate(0.7%) rotate(180deg);
}
.righttabs.customtabsservices li .nav-link.active::after{
  background: #076A81;
}
.accordion-button img{width: 80px;margin-right: 10px;}
.accordion-button{font-size: 28px;}
.stratargyicon{width: 150px;}
}
@media (min-width:1200px){
  .customtabsservices li .nav-link img{width: 60px;}
  .customtabsservices li .nav-link{font-size: 20px; line-height: 22px;}
}
@media (max-width: 991px) {
  .tab-content>.tab-pane {
    display: block;
    opacity: 1;
  }
}